<template>
  <div>
    <eden-page-header :title="'Gardeners'" :subtitle="'Edit Profile'" />
    <gardener-form action="edit" :gardener="gardener" :loading.sync="loading" />
  </div>
</template>

<script>
import GardenerForm from "@/components/Gardeners/Gardener/GardenerForm";

import * as actions from "@/store/action-types";
import gardener from "@/requests/gardeners/gardener";

export default {
  name: "GardenerEdit",
  components: {
    GardenerForm,
  },
  data() {
    return {
      gardener: {},
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST).then(() => {
      this.getGardener();
    });
  },
  methods: {
    getGardener() {
      gardener
        .get(this.$route.params.id)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data.gardener;
            this.gardener.id = data.id;
            this.gardener.avatar = data.avatar;
            this.gardener.first_name = data.name.split(" ")[0];
            this.gardener.last_name = data.name.split(" ")[1];
            this.gardener.email = data.email;
            this.gardener.phoneNo = data.phone_number;
            this.gardener.address = data.home_address;
            this.gardener.bio = data.sweet_bio;
            this.loading = false;
          }
        })
        .catch();
    },
  },
};
</script>

<style scoped lang="scss"></style>
