<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-folder="'gardeners'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Personal"
        subtitle="Let’s get to know this gardener"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="first_name"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.first_name"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="last_name"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.last_name"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Email" prop="email" :rules="validateEmail()">
                <el-input
                  type="text"
                  v-model="form.email"
                  :disabled="action === 'edit'"
                  placeholder="you@mail.com"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Phone Number"
                prop="phoneNo"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.phoneNo"
                  v-number
                  :maxlength="11"
                  placeholder="+234 812 345 6789"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Other"
        subtitle="Enter some extra information about this gardener"
      >
        <template slot="form-fields">
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Bio" prop="bio" :rules="validateField()">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.bio"
                  placeholder="Something cool about this gardener"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Address"
                prop="address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.address"
                  placeholder="Where does the gardener live?"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            v-if="action === 'add'"
            type="flex"
            :gutter="30"
            class="is-flex-wrap"
          >
            <el-col :md="24">
              <el-form-item
                label="Location area"
                prop="location_area_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.location_area_id"
                  filterable
                  multiple
                  placeholder="Which location areas will you be serving?"
                >
                  <el-option
                    v-for="area in locationareas"
                    :key="area.id"
                    :label="area.name"
                    :value="area.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="eden-page-form__actions">
            <el-button plain @click="$router.go(-1)">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="addNewGardener"
              >Add Gardener
            </el-button>
            <el-button
              v-else
              type="primary"
              :loading="updating"
              @click="updateGardenerProfile"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import gardener from "@/requests/gardeners/gardener";

export default {
  name: "GardenerForm",
  props: {
    action: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        avatar: "",
        first_name: "",
        last_name: "",
        email: "",
        phoneNo: "",
        bio: "",
        address: "",
        location_area_id: [],
      },
      uploadingPhoto: false,
      adding: false,
      updating: false,
    };
  },
  computed: {
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
    setLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.form = { ...this.gardener };
        delete this.form.location_area_id;
      }
    },
  },
  methods: {
    addNewGardener() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.form.email = this.form.email.toLowerCase();
        this.adding = true;
        gardener
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$router.push({ name: "gardeners.index" });
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    updateGardenerProfile() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;

        this.form.full_name = this.form.first_name + " " + this.form.last_name;

        gardener
          .update(this.form.id, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$router.push({
                name: "gardeners.gardener",
                params: { id: this.form.id },
              });
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
